<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import ListingTable from "@/components/listings/listing-table";

export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        ListingTable,
    },
    data() {
        return {
            title: "Role",
            items: [
                {
                    text: "Role"
                },
            ],
            fields: [
                { key: "id", sortable: true, label: 'ID' },
                { key: 'role', sortable: true, label: 'Role' },
                { key: 'display_name', sortable: true, label: 'Name' },
                { key: "action" },
            ],
            addUrl: `${window.location.origin}/role/form/add`,
        };
    },
    methods: {
        deleteRecord(id) {
            const deleteCallback = async () => {
                await axios.delete(`${process.env.VUE_APP_APIURL}/setting/role/${id}`, {
                    headers: authHeader(),
                });
                this.filterTable();
                this.$refs.confirmationModal.closeModal();
                this.$refs.messageModal.showModal('The record has been deleted');
            }
            this.$refs.confirmationModal.showModal(`Confirm delete role (ID: ${id})?`, deleteCallback);
        },

        filterTable() {
            this.$refs.listingTable.filterTable('', '');
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <!-- <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0">
                        <div class="row mt-2 ml-1">
                            <router-link class="btn btn-success mb-2" :to="{name: 'Role Form', params: {id: 'add'}}"><i class="mdi mdi-plus mr-2"></i> Add New Role</router-link>
                        </div>
                    </div>
                    <div class="pl-4 pr-4">
                        <ListingTable ref="listingTable" :fields="fields" :setAdminView="setAdminView" path="setting/role" listName="roles">
                            <template v-slot:cell(action)="{ item }">
                                <router-link class="mr-3 text-primary" :to="{name: 'Role Form', params: {id: item.id}}"><i class="mdi mdi-pencil font-size-18" v-b-tooltip.hover title="Edit"></i></router-link>
                                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                                    @click="deleteRecord(item.id)">
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </template>
                        </ListingTable>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body pt-0" style="position: relative;">
						<div class="filter_buttons">
							<a class="add_new_button" :href="addUrl">
                                <i class="mdi mdi-plus mr-2 font-size-17"></i> Add New Role
                            </a>
						</div>

						<ListingTable ref="listingTable" :fields="fields" path="setting/role" listName="roles">
                            <template v-slot:cell(action)="{ item }">
                                <router-link class="mr-3 text-primary" :to="{name: 'Role Form', params: {id: item.id}}"><i class="mdi mdi-pencil font-size-18" v-b-tooltip.hover title="Edit"></i></router-link>
                                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete" @click="deleteRecord(item.id)">
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </template>
                        </ListingTable>
					</div>
				</div>
			</div>
		</div>
    </Layout>
</template>